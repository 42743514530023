// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
    production: false,

    ws: 'https://hybrido.dev.ayudatelta.com/api/',
    wsREP: 'https://repws2.ayudatelta.com/api/',
    baseBackend: 'https://hybrido.dev.ayudatelta.com/',
    app: 'https://solicitud.dev.ayudatelta.com/#/',
    ws_php: 'https://app.ayudatelta.com/',
    ws_webSocket: 'https://appv2ws.dev.ayudatelta.com/',
    FeatureFlagURL: 'https://d23sb1amsbr6m.cloudfront.net/flags.json',
    WS_Admin: 'https://appv2ws.dev.ayudatelta.com/api/',
    WS_GraphQL: 'https://solicitudv2ws.dev.ayudatelta.com/graphql',
};
